export function GetTranslationJson() {
  let lastUpdate = localStorage.getItem("lastTranslationUpdate");
  let lastCall = localStorage.getItem("lastCall");
  let forceUpdate = false;
  const translationJson = localStorage.getItem("translationJson");
  if (lastUpdate === null || lastUpdate === undefined) {
    localStorage.setItem("lastTranslationUpdate", Date.now().toString());
    lastUpdate = Date.now().toString();
    forceUpdate = true;
  }
  if (lastCall === null || lastCall === undefined) {
    localStorage.setItem("lastCall", Date.now().toString());
    lastCall = 0;
  }
  if (
    translationJson === null ||
    translationJson === undefined ||
    translationJson === []
  ) {
    lastUpdate = 0;
  }
  // If the current user is a support guy, we notify it to the backend
  // As for managers, the currently stored json is a lighter one with only globals and JS nodes, we need to replace it for the support guy
  let args = "";
  if (
    document.baseURI === undefined ||
    document.baseURI.indexOf("support") !== -1 ||
    $(".isSupport").length > 0
  ) {
    args = `?Date=${lastUpdate}&isSupport=${true}`;
    forceUpdate = true;
  } else {
    args = `?Date=${lastUpdate}`;
  }
  // As every bundle have a call to this function, we have to make sure that everyone is not actually calling it
  // Atm we call it once a day (the date.Now() returns the date in milliseconds)
  if (Date.now() - lastCall * 1 > 86400000 || forceUpdate === true) {
    localStorage.setItem("lastCall", Date.now().toString());
    $.ajax({
      url: `/rest/language/translations/json${args}`,
      headers: {
        "Content-Type": "application/json",
      },
      mimeType: "application/json",
      method: "get",
      dataType: "text",

      success(response) {
        if (response !== "") {
          localStorage.setItem("lastTranslationUpdate", Date.now().toString());
          localStorage.setItem(
            "translationJson",
            JSON.stringify(JSON.parse(response)),
          );
          console.log("translation Json updated");
        }
      },
    });
  }
}

class Translation {
  constructor() {
    const saveButton = $("#translate-save-button");

    saveButton.click(() => {
      $(saveButton).attr("disabled", "disabled");
      saveButton[0].style.backgroundColor = "#969696";
    });

    $(".translationValue").on("keydown", () => {
      $(saveButton[0]).prop("disabled", false);
      saveButton[0].style.backgroundColor = "#439e8d";
    });
    // Force a json update after an import
    if ($("#isUpdating")[0] !== undefined) {
      localStorage.setItem("translationJson", null);
      localStorage.setItem("lastCall", 0);
      localStorage.setItem("lastTranslationUpdate", 0);
      GetTranslationJson();
    }
    $(".incompleteCheckBox").on("click", (event) => {
      this.ShowIncomplete(event);
    });
    $(".langCheckBox").on("click", (event) => {
      this.HideLanguage(event);
    });
    $("#translate-save-button").on("click", () => {
      this.SaveJson();
    });
    $("#importButton").on("change", () => {
      Translation.ImportJson();
    });
    this.SaveJson();
    setInterval(() => {
      if (localStorage.getItem("lastTranslationUpdate") * 1 !== 0) {
        this.SaveJson();
      }
      GetTranslationJson();
    }, 10000);
    this.deleteBtn = $("#langRemoveBtn");
    this.addBtn = $("#languages_form_Sauvegarder");
    // Force a json update after deleting a language
    if (this.deleteBtn[0] !== undefined) {
      this.deleteBtn.on("click", () => {
        localStorage.setItem("lastTranslationUpdate", 0);
        localStorage.setItem("lastCall", 0);
        GetTranslationJson();
      });
    }

    // Force a json update after adding a language
    if (this.addBtn[0] !== undefined) {
      this.addBtn.on("click", () => {
        localStorage.setItem("lastTranslationUpdate", 0);
        localStorage.setItem("lastCall", 0);
        GetTranslationJson();
      });
    }
  }

  /**
   * Set a dynamic text with his translation to an element
   * @param element
   * @param elementNode
   * @param defaultText
   * @param nodeName
   * @param parameters
   */
  static Set(element, elementNode, defaultText, nodeName, parameters = null) {
    const lang = localStorage.getItem("lang");
    const Translations = JSON.parse(localStorage.getItem("translationJson"));
    let text = "";
    if (Translations === undefined || Translations == null) {
      console.log(
        "Wasnt't able to load the translation file, loading the default translation...",
      );
      return Translation.format(defaultText, parameters);
    }
    if (
      Translations.JS === undefined ||
      Translations.JS[nodeName] === undefined ||
      Translations.JS[nodeName][lang] === "" ||
      Translations.JS[nodeName][lang] === undefined
    ) {
      if (
        Translations.Global === undefined ||
        Translations.Global[nodeName] === undefined ||
        Translations.Global[nodeName][lang] === "" ||
        Translations.Global[nodeName][lang] === undefined
      ) {
        // add the node to the translation file and show the default translation
        if (lang !== "" && lang !== " " && defaultText !== "") {
          Translation.AddMissingNode(defaultText, nodeName, lang);
        }
        text = Translation.format(defaultText, parameters);
      } else {
        text = Translation.format(
          Translations.Global[nodeName][lang],
          parameters,
        );
      }
    } else {
      text = Translation.format(Translations.JS[nodeName][lang], parameters);
    }
    if (element !== null) {
      Translation.setText(element, elementNode, text);
    }
    return text;
  }

  static format(string, parameters) {
    let translation = "";

    const groups = string.split("%");
    if (parameters !== null && groups.length >= 2) {
      for (let i = 0; i < groups.length; i += 1) {
        if (groups[i].length > 1 && groups[i][0] === "$") {
          translation = translation.concat(parameters[groups[i][1]]);
        } else {
          translation = translation.concat(groups[i]);
        }
      }
      return translation;
    }
    return string;
  }

  static setText(element, elementNode, translation) {
    if (elementNode === "setCustomValidity") {
      element.setCustomValidity(translation);
    }
    if (elementNode === "html") {
      element.html(translation);
    }
    if (elementNode === "innerHTML") {
      element.innerHTML = translation;
    }
    if (elementNode === "innerText") {
      element.innerText = translation;
    }
    if (elementNode === "placeholder") {
      element.placeholder = translation;
    }
    if (elementNode === "value") {
      element.value = translation;
    }
    if (elementNode === "textContent") {
      element.textContent = translation;
    }
  }

  static ImportJson() {
    const btn = $("#importButton")[0];
    const file = btn.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      localStorage.setItem("lastTranslationUpdate", 0);
      $("#translation_form_translation")[0].value = event.target.result;
      $("form[name='translation_form']").submit();
    };
    reader.readAsText(file);
  }

  static AddMissingNode(text, name, lang) {
    const node = `{ "${name}" : "${text}" }`;
    const Translations = JSON.parse(localStorage.getItem("translationJson"));
    $.ajax({
      type: "POST",
      url: "/rest/language/translations/add-js-node",
      data: node,
      contentType: "application/json",
      dataType: "json",
    });
    if (Translations.JS[name] === undefined) {
      Translations.JS[name] = {};
    }
    Translations.JS[name][lang] = text;
    localStorage.setItem(
      "translationJson",
      JSON.stringify(Translations, null, "\t"),
    );
  }

  ShowIncomplete(event) {
    const translationRow = $(".card-body");
    for (let i = 0; i < translationRow.length; i += 1) {
      const langValues = $(translationRow[i]).find(".translationValue");
      if (event.currentTarget.checked === true) {
        let incomplete = false;
        for (let j = 0; j < langValues.length; j += 1) {
          if (langValues[j].value === undefined || langValues[j].value === "") {
            incomplete = true;
          }
        }
        if (incomplete !== true) {
          $(translationRow[i]).hide();
        }
      } else {
        $(translationRow[i]).show();
      }
    }
  }

  HideLanguage(event) {
    const langNode = $(`.${event.currentTarget.name}`);
    for (let i = 0; i < langNode.length; i += 1) {
      if (event.currentTarget.checked === false) {
        langNode[i].style.display = "none";
      } else {
        langNode[i].style.display = "inherit";
      }
    }
  }

  SaveJson() {
    // We don't want to update a null or a just updated json
    if (JSON.parse(localStorage.getItem("translationJson")) === null) {
      return;
    }
    const nodes = $(".translationRow");
    const Translations = JSON.parse(localStorage.getItem("translationJson"));
    let changed = false;
    for (let i = 0; i < nodes.length; i++) {
      const key = $(nodes[i]).find(".translationKey")[0].value;
      const nodeKey = $(nodes[i]).find(".translationNodeKey")[0].value;
      const tag = $(nodes[i]).find(".translationTag")[0].value;
      const value = $(nodes[i]).find(".translationValue")[0].value;
      if (Translations[key] === undefined) {
        console.log(`key ${key} is not defined in json`);
      } else if (Translations[key][nodeKey] === undefined) {
        console.log(`${nodeKey} is not defined in json for key${key}`);
      } else if (Translations[key][nodeKey][tag] !== value) {
        Translations[key][nodeKey][tag] = value;
        changed = true;
        localStorage.setItem(
          "translationJson",
          JSON.stringify(Translations, null, "\t"),
        );
      }
    }
    if (changed) {
      console.log("translation saved");
      $.ajax({
        type: "POST",
        url: "/support/rest/admin/language/translations/update",
        data: JSON.stringify(Translations, null, "\t"),
        contentType: "application/json",
        dataType: "json",
      });
      localStorage.setItem("lastCall", 0);
      this.DisableButtons();
    }
  }

  DisableButtons() {
    GetTranslationJson();
    const exportBtn = $("#exportButton")[0];
    const importBtn = $("#importButton")[0];
    const saveButton = $("#translate-save-button");
    $(importBtn).attr("disabled", "disabled");
    $(exportBtn).attr("disabled", "disabled");
    const timer = setTimeout(() => {
      $(importBtn).attr("disabled", "");
      $(exportBtn).attr("disabled", "");
      clearTimeout(timer);
    }, 3000);
    if (saveButton[0] !== undefined) {
      $(saveButton).attr("disabled", "disabled");
      saveButton[0].style.backgroundColor = "#969696";
    }
  }
}

$(window).on("load", () => {
  new Translation(document);
});
export default Translation;
