import NanoPopup from "../../../components/Popups/NanoPopup";
import RestPromise from "../../../utils/RestPromise";

class ImportTemplate {
  constructor() {
    this.importButton = $(".import-button");
    this.importPopup = new NanoPopup({
      target: "#challenge-import-popup",
      duration: 450, // default 350
      delay: 100, // default 50
      onCancel: () => {
        this.importPopup.Hide();
      },
      onAction: () => {
        this.CheckCode();
      },
      willOpen: () => {
        $(".popup-text-row").val("");
      },
      isHidden: () => {
        $(".popup-text-row").val("");
      },
    });

    this.importButton.click(() => {
      this.importPopup.Show();
    });
  }

  CheckCode() {
    const code = this.importPopup.GetInputValue().inputText;
    this.importPopup.Waiting();
    if (code.trim().length >= 4) {
      this.lastCheckedCode = code;
      RestPromise.Create(`/rest/challenges/check-sharing-code/${code}`, "GET")
        .then((result) => {
          this.importPopup.Success();
          window.location.replace(`/challenges/import-template/${code}`);
        })
        .catch((error) => {
          RestPromise.DisplayRestMessage(
            error.responseJSON.message,
            "warning",
            "fa-exclamation",
          );
          this.importPopup.Reset();
        });
    } else {
      this.importPopup.Error();
    }
  }
}

export default ImportTemplate;
