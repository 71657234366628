import "../../resources/less/Globals.less";
import "../../resources/less/normalize.less";
import "../../resources/less/Constellation/theme.less";
import "../../resources/less/Constellation/components/Inputs.less";
import "../../resources/less/components/Buttons/CommonButtons.less";
import "../../resources/less/Constellation/components/Buttons.less";
import LexiconPopup from "./Help/LexiconPopup";
import ImportTemplate from "./Challenge/Template/ImportTemplate";
import VideoTutorial from "../components/Help/VideoTutorial";
import { GetTranslationJson } from "../Translation";

class ConstellationMain {
  constructor() {
    new LexiconPopup();
    new ImportTemplate();
    new VideoTutorial();

    const lastUpdate = JSON.parse(
      localStorage.getItem("lastTranslationUpdate"),
    );
    if (lastUpdate === null || lastUpdate === undefined) {
      GetTranslationJson();
    }
    // check for changes in the translation file every minutes
    setInterval(() => {
      GetTranslationJson();
    }, 60000);
  }
}

$(window).on("load", () => {
  new ConstellationMain();
});
