import "../../../resources/less/components/Lexicon.less";

export default class LexiconPopup {
  constructor() {
    this.lexiconPopup = $(".lexicon-popup");
    this.lexiconContainer = $(".lexicon-container");
    this.lexiconClose = this.lexiconPopup.find(".popup-close");
    this.lexiconButton = $(".lexicon-button");

    if ($("#display-lexicon-on-login").length !== 0) {
      this.lexiconPopup.addClass("is-visible");
    }

    this.lexiconButton.click(() => {
      this.lexiconPopup.addClass("is-visible");
    });

    this.lexiconClose.click(() => {
      this.lexiconPopup.removeClass("is-visible");
    });

    this.lexiconPopup.click((e) => {
      if ($(e.target).hasClass("lexicon-popup")) {
        this.lexiconPopup.removeClass("is-visible");
      }
    });
  }
}
